<template>
  <div class="container">
     <div v-if="isLoggedIn != true"  class="login_form_outer">
      <div v-if="loader">
          <ProgressSpinner id="loader" />
      </div>
      <p class="error" v-if="errors.length">
               <ul>
                  <li v-for:='error in errors'>{{ error }}</li>
               </ul>
        </p>
      <Card>
          <template #header>
              Login with Password
          </template>
          <template #content>
            <form @submit="login_form" method="post" class="form_login">
                  <div class="field col-12 md:col-4">
                          <span class="p-float-label">
                              <Password id="inputtext" type="password" v-model="password" :feedback="false" toggleMask required/>
                              <label for="inputtext">Password</label>
                          </span>
                      </div>
                  <Button type="submit" icon="pi pi-sign-in" class="btn btn-primary" label="Submit!" />
                  

              </form>
          </template>
      </Card>
        </div>
        <div v-else class="shopdetails">
            <h1>Welcome to the MetaField app for CoutureCandy!</h1>
            <div class="shopDate">
                {{shop_con?.name?shop_con.name:null}}
            </div>
            <div class="shop_details">
                    
          <Splitter>
            <SplitterPanel class="flex align-items-center justify-content-center" :size="20" :minSize="10">
              <div v-if="shop_con" class="details">
                <h3 class="color_blue">Shop Details</h3>
                  <ul>
                    <li v-for=" value in shop_con?.details" :key="value.id">
                          {{ value }}
                    </li>
                  </ul>
              </div>
            </SplitterPanel>
            <SplitterPanel :size="80">
              <Splitter layout="vertical">
                <SplitterPanel class="flex align-items-center justify-content-center" :size="15">
                  <h2 class="color_blue">FAQ</h2>
                </SplitterPanel>
                <SplitterPanel class="faq_sec" :size="85">
                  <Splitter>
                    <SplitterPanel class="flex align-items-center justify-content-center" :size="80">
                      <Accordion :multiple="true">
                        <AccordionTab>
                          <template #header>
                            <span>Step 1 Vendors</span>
                          </template>
                          Go to vendor page, please select any of the vendor and get the list of variants which have the quantity 1200.
                          <a href="/vendors" class="btn">vendor list</a>
                        </AccordionTab>
                        <AccordionTab>
                          <template #header>
                            <span>Step 2 Download records</span>
                          </template>
                          Please download all the variants of the selected vendor form variants page, then update or add the date to the CSV sheet.
                        </AccordionTab>
                        <AccordionTab>
                          <template #header>
                            <span>Step 3 Update Records</span>
                          </template>
                          You can upload the downloaded  over the upload page.  <a href="/upload" class="btn">Upload</a><br/><br/>
                          <strong>
                          Note: Please only upload the records that need to be updated. The process will take time, depending on the number of records being uploaded.</strong>
                        </AccordionTab>
                      </Accordion>
                      
                    </SplitterPanel>
                  </Splitter>
                </SplitterPanel>
              </Splitter>
            </SplitterPanel>
          </Splitter>
            </div>
        </div>
        <div v-bind:class="(loader)?'loading':'loaded'"></div> 
  </div>
</template>

<script>
import { mapActions,mapGetters } from "vuex";
  // import InputText from 'primevue/inputtext';
  import Card from 'primevue/card';
  import Button from 'primevue/button';
  import Splitter from 'primevue/splitter';
  import SplitterPanel from 'primevue/splitterpanel';
  import ProgressSpinner from 'primevue/progressspinner';
  import Password from 'primevue/password';
  import Accordion from 'primevue/accordion';
  import AccordionTab from 'primevue/accordiontab';
  
export default {
  name: 'HelloWorld',
   components: {
    Card,
    Splitter,
    Button,
    SplitterPanel,
    Password,
    ProgressSpinner,
    Accordion,
    AccordionTab
   },
    data() {
      return {
        errors:[],
        password:"",
        loader:false
      }
   },
    methods: {
         ...mapActions(["LogIn","LogOut","shop"]),
         ...mapGetters(["isAuthenticated","shopDetails","userToken"]), 
       login_form:async function (e) {
              this.loader = true;
               e.preventDefault();
               this.errors = [];
               const isLogin = await this.LogIn(this.password);
               if(this.userToken){
                  await this.shop(this.userToken);
               }
                if(isLogin == false){
                  this.errors.push("Password not correct!");
                  return
                }else{
                    this.isLoggedIn = true;
                    this.loader = false;
                }
          },
    },
    computed: {
      isLoggedIn : function(){
          return this.$store.getters.isAuthenticated
      },
      shop_con : function(){
          return this.$store.getters.shopDetails
      },
      userToken : function(){
          return this.$store.getters.getUserToken
      },
    },


}
</script>